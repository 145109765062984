import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "../App.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const setSelectValue = () => {
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary" elevation={2}>
        <Toolbar>
          <div>
            <Link to={"/"}>
              <img
                src={require("../Images/hk-logotxt.png")}
                alt="logo"
                className="header_logo"
              />
            </Link>
          </div>
          {/* <div>
            <Button
              id="fade-button"
              color="inherit"
              sx={{ mr: 5 }}
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Menu1
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <NestedMenuItem
                label={"dropdown1"}
                parentMenuOpen={open}
                style={{ marginLeft: "5px" }}
                className="nestedmenu"
              >
                <MenuItem
                  onClick={(e) => {
                    setSelectValue(e.currentTarget.dataset.value);
                  }}
                >
                  submenu1
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setSelectValue(e.currentTarget.dataset.value);
                  }}
                >
                  submenu2
                </MenuItem>
              </NestedMenuItem>
              <MenuItem onClick={handleClose}>dropdown2</MenuItem>
              <MenuItem onClick={handleClose}>dropdown3</MenuItem>
            </Menu>
          </div>
          <Link to={"/secondpage"}>
            <Button color="inherit" sx={{ mr: 5 }}>
              Menu2
            </Button>
          </Link>
          <Button color="inherit">Menu3</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
