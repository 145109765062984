import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import {Helmet} from "react-helmet";

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('xs')]: {
    minWidth: 300,
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('xs')]: {
    minWidth: 380,
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 600,
    position: 'absolute',
    left: '50%',
    top: '45%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 800,
    position: 'absolute',
    left: '50%',
    top: '35%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 800,
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 1000,
    position: 'absolute',
    left: '50%',
    top: '25%',
    transform: 'translate(-50%, -50%)'
  },
}));

const InstaDownloader = () => {
  const [textValue, setTextValue] = useState("");
  const [posts, setPosts] = useState([]);
  const [severity, setSeverity] = useState("");
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState("")

  const client = axios.create({
    baseURL: "https://api2.hexkey.io/api/svc_instaloader/",
  });

  const urlPatternValidation = (URL) => {
    const regex = new RegExp('((http|https):\/\/)?(www.)?(instagram.com\/p)([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])');
    return regex.test(URL);
  };

  const validate = (value) => {
    if (urlPatternValidation(value)) {
      setValidation("")
      return true;
    } else {
      setValidation('Please use valid instagram post URL!');
      return false;
    }
  };

  const handleChange = (event) => {
    setTextValue(event.target.value);
  };

  const addPosts = (message) => {
    client
      .post("", {
        message,
      })
      .then((response) => {
        setPosts(response.data);
        setSeverity("success");
        setLoader(false);
      })
      .catch(() => {
        setSeverity("error");
      });
  };

  const hadleClick = (e) => {
    e.preventDefault();
    if (!validate(textValue)) {
      return false;
    }
    addPosts(textValue);
    setLoader(true);
  };

  useEffect(() => {
    if (severity !== "")
      setTimeout(() => {
        setSeverity("");
      }, 3000);
  }, [severity]);

  return (
    <Root>
      <Helmet>
        <title>Instagram Downloader</title>
        <meta name="description" content="Download Instagram videos, reels, IGTV and Photos" />
      </Helmet>
      <Card>
        <CardContent>
          <Typography variant="h4">Instagram URL</Typography>
          <Typography variant="h4" component="div">
            <div className="raper">
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onChange={handleChange}
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={hadleClick}
              >
                Download
              </Button>
            </div>
          </Typography>
          {loader && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "center"}}
              pt={2}
            >
              {validation}
            </Box>
            <Alert severity={severity}>{severity}</Alert>
          </Stack>
        </CardContent>
      </Card>
      <Typography mt={4} variant="h4">Download Post Items Below</Typography>
      <Grid container spacing={3} mt={3}>
        {posts.map(post => 
          <Grid item key={post} xs ={4}>
            {/* <Card sx={{ minWidth: 200 }} >
              <CardContent>
                <CardMedia
                  component="img"
                  height="1000"
                  width="3500"
                  image={StudentData}
                />
              </CardContent>
            </Card> */}
            <Button
                color="primary"
                variant="contained"
                href={post.toString() + "&dl=1"}
              >
                Download
            </Button>
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

export default InstaDownloader;