import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Content from './Content';
import InstaDownloader from './InstaDownloader';

const Routefile = () => {
  return (
      <Routes>
        <Route exact path="/" element={<Content />} />
        <Route exact path="/instagram-downloader" element={<InstaDownloader />} />
      </Routes>
  );
}

export default Routefile;