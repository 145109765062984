import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardMedia, Link } from "@mui/material";
import Image from "../Images/cardPicture.jpg";
import Image2 from "../Images/small-insta-logo-color.png"
import Grid from "@mui/material/Grid";

const Content = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      m={2} pt={3}
    >
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Link
            href={"https://hexkey.io/sinatra/sinatra"}
            style={{ textDecoration: "none" }}
          >
            <Card sx={{ minWidth: 200 }} className="card_main">
              <CardContent>
                <Typography variant="h6">Sinatra API Check</Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  Basic API to check backend infrastructure uptime
                </Typography>
              </CardContent>
              <CardMedia component="img" sx={{ width: 120 }} image={Image} />
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Link
            href={"/instagram-downloader"}
            style={{ textDecoration: "none" }}
          >
            <Card sx={{ minWidth: 200 }} className="card_main">
              <CardContent>
                <Typography variant="h6">Instagram Downloader</Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  Download Instagram videos, reels, photos and IGTV
                </Typography>
              </CardContent>
              <CardMedia component="img" sx={{ width: 120 }} image={Image2} />
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Content;
