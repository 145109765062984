import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      light: '#fff17f',
      main: '#f7bf4f',
      dark: '#c18f1b',
      contrastText: '#261701',
    },
    secondary: {
      light: '#c8df67',
      main: '#95ad37',
      dark: '#647e00',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});